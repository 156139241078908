@font-face {
  font-family: 'Averta';
  src: url('hinted-AvertaStd-Regular.woff2') format('woff2'),
    url('hinted-AvertaStd-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Averta';
  src: url('hinted-AvertaStd-Bold.woff2') format('woff2'),
    url('hinted-AvertaStd-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Averta';
  src: url('hinted-AvertaStd-Semibold.woff2') format('woff2'),
    url('hinted-AvertaStd-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}
